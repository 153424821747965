import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { dashboardPaths } from "navigation/DashboardRoutes";
import { Button } from "@mui/material";
import { useAppState } from "contexts/AppStateContext";
import { create } from "braintree-web-drop-in";
import { CustomerStatus } from "constants/enums_customer";
import FunctionsApiService from "services/FunctionsApiService";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import StaticNotification from "components/StaticNotification";
import { PostIdentStatus } from "constants/enums_postident";
import { SubscriptionPaymentStatus } from "constants/enums_subscription_payment";
import InfoBox from "components/InfoBox";
import { overlayPaths } from "navigation/OverlayRoutes";
import ItemProfileDetails from "components/ItemProfileDetails";
import { objectsAreEqual } from "concerns/checkout/services/Utils";
import { useTranslation } from "react-i18next";
import LoadingView from "components/LoadingView";

export default function UserDataView() {
    const { strings, customer, customerSubscription, customerPostIdent, customerGunnebo } = useAppState();
    const { t } = useTranslation();
    const history = useHistory();
    const [paymentMethod, setPaymentMethod] = useState({});
    const [paymentInput, setPaymentInput] = useState({ open: false, braintreeInstance: null, braintreeRequestable: false, errorMessage: '', amount: 0 });
    const [hasPaymentIssue, setHasPaymentIssue] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const updatePaymentData = useCallback((changedData) => {
        setPaymentInput(prevState => {
            return { ...prevState, ...changedData }
        });
    }, [setPaymentInput])

    const resetPaymentData = useCallback(() => {
        setPaymentInput({ open: false, braintreeInstance: null, braintreeRequestable: false, errorMessage: '', amount: 0 });
    }, [setPaymentInput])

    useEffect( () => {
        if(customer) setIsLoading(false);
    }, [customer])

    useEffect(() => {
        if (!customer) return;
        setPaymentMethod(customerSubscription?.braintree?.paymentMethod);
        setHasPaymentIssue(customerSubscription?.paymentCurrentStatus === SubscriptionPaymentStatus.FAILED);
    }, [setIsLoading, customer, customerSubscription]);

    const handlePaymentClick = useCallback(() => {
        setIsLoading(true);
        updatePaymentData({ open: true, errorMessage: '' });

        const createBraintree = async () => {
            try {
                const payload = {
                    nextBillingAmount: true,
                }
                const data = await FunctionsApiService.paymentsClientToken(payload);
                setIsLoading(false);

                create({
                    authorization: data.data.token,
                    container: '#braintree-container',
                    locale: 'de_DE',
                    paypal: {
                        flow: 'vault',
                        buttonStyle: {
                            color: 'gold',
                            shape: 'rect',
                            size: 'responsive',
                            tagline: false
                        }
                    },
                    threeDSecure: true,
                    card: {
                        overrides: {
                            fields: {
                                number: {
                                    supportedCardBrands: {
                                        "maestro": false
                                    },
                                }
                            }
                        }
                    },
                    applePay: {
                        displayName: 'Asservato GmbH',
                        paymentRequest: {
                            total: {
                                label: 'Asservato GmbH',
                                amount: '0.1'
                            },
                            // We recommend collecting billing address information, at minimum
                            // billing postal code, and passing that billing postal code with all
                            // Apple Pay transactions as a best practice.
                            requiredBillingContactFields: ["postalAddress"]
                        }
                    },
                    googlePay: {
                        googlePayVersion: 2,
                        merchantId: 'BCR2DN4T27N43ADV',
                        merchantInfo: {
                            merchantName: "Asservato GmbH",
                        },
                        transactionInfo: {
                            currencyCode: 'EUR',
                            countryCode: 'DE',
                            totalPriceStatus: 'FINAL',
                            totalPrice: '0.1',
                        },
                        allowedPaymentMethods: [{
                            type: 'CARD',
                            parameters: {
                              // We recommend collecting and passing billing address information with all Google Pay transactions as a best practice.
                              billingAddressRequired: true,
                              billingAddressParameters: {
                                format: 'FULL'
                              }
                            }
                        }]
                    }
                }, (createErr, instance) => {
                    setIsLoading(false);

                    if (createErr) {
                        throw new Error(createErr)
                    }

                    updatePaymentData({
                        amount: data.data.nextBillingAmount,
                        braintreeInstance: instance
                    });

                    if (instance.isPaymentMethodRequestable()) {
                        updatePaymentData({
                            braintreeRequestable: true
                        });
                    }

                    instance.on('paymentMethodRequestable', (event) => {
                        updatePaymentData({
                            braintreeRequestable: true
                        });
                    });

                    instance.on('noPaymentMethodRequestable', () => {
                        updatePaymentData({
                            braintreeRequestable: false
                        });
                    });
                });

            } catch (error) {

                updatePaymentData({
                    open: false,
                    errorMessage: strings['ERROR_payment/change-method-failed'],
                });
                setIsLoading(false);

            }
        }

        createBraintree();

    }, [updatePaymentData, setIsLoading, strings]);

    const handleDialogConfirm = useCallback(box => {

        const addPaymentMethod = async () => {

            try {

                let threeDSecureParameters = {
                    amount: paymentInput.amount?.toString(),
                    challengeRequested: true,
                    email: customer.email,
                    billingAddress: {
                        givenName: customer.firstName,
                        surname: customer.lastName,
                        streetAddress: customer.address.street + ' ' + customer.address.streetNo,
                        locality: customer.address.city,
                        postalCode: customer.address.zip,
                        countryCodeAlpha2: customer.address.country,
                    }
                };

                let nonce = await new Promise((resolve, reject) => {
                    paymentInput.braintreeInstance.requestPaymentMethod(
                        {
                            threeDSecure: threeDSecureParameters
                        },
                        (requestPaymentMethodErr, payload) => {
                            if (requestPaymentMethodErr) {
                                setIsLoading(false);
                                resetPaymentData();
                                reject(requestPaymentMethodErr);
                                return;
                            }

                            setIsLoading(true);
                            resolve(payload.nonce);
                        }
                    )
                }
                )

                await FunctionsApiService.paymentsUpdateSelectedMethod({ nonce: nonce })

                setIsLoading(false);
                resetPaymentData();

            } catch (error) {

                let errorInfo = (error?._braintreeWebError ? '(' + error?._braintreeWebError?.code + ' | ' + error?._braintreeWebError?.message + ')' : '')
                setIsLoading(false);
                updatePaymentData({
                    open: false,
                    errorMessage: strings['ERROR_payment/change-method-failed'] + errorInfo
                });
            }
        }

        addPaymentMethod();

    }, [paymentInput.amount, paymentInput.braintreeInstance, updatePaymentData, resetPaymentData, customer, setIsLoading, strings]);

    const handleDialogClose = useCallback(() => {
        resetPaymentData();
    }, [resetPaymentData]);

    /*if (!customer) {
        return <></>;
    }*/

    if(isLoading){
        return <LoadingView/>
    }

    return (
        <div className="user-data-container">
            <div className="page-header">
                <Button className="mobile-icon" onClick={() => document.body.classList.add('show-mobile-menu')}>
                    <img className="menu-icon"
                        srcSet={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png, ${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white@2x.png 2x`}
                        src={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png`}
                        alt="Menu Icon"
                    />
                </Button>

                {strings["ENTER_USER_DATA_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon /></Button>
            </div>

            <div className="padded-container">
                {
                    customer.status === CustomerStatus.REGISTERED ?
                        <div>
                            <ItemProfileDetails
                                title={[t(customer.salutation), customer.title ? t(customer.title) : '', customer.firstName, customer.lastName].join(' ')}
                                detailOne={customer.company}
                                detailTwo={customer.email}
                                detailThree={customer.mobilePhone}
                                onClick={() => {
                                    history.push(overlayPaths.editContactDetails)
                                }}
                            />

                            <ItemProfileDetails
                                title={objectsAreEqual(customer.address, customer.billingAddress) ? "Liefer- & Rechnungsadresse" : "Lieferadresse"}
                                detailOne={customer.address.street + ' ' + customer.address.streetNo}
                                detailTwo={customer.address.zip + ' ' + customer.address.city + ' - ' + customer.address.country}
                                onClick={() => history.push(overlayPaths.editAddress)}
                            />

                            {!objectsAreEqual(customer.address, customer.billingAddress) && customer.billingAddress &&
                                <ItemProfileDetails
                                    title={"Rechnungsadresse"}
                                    detailOne={customer.billingAddress?.street + ' ' + customer.billingAddress?.streetNo}
                                    detailTwo={customer.billingAddress?.zip + ' ' + customer.billingAddress?.city + ' - ' + customer.billingAddress?.country}
                                    onClick={() => history.push(overlayPaths.editAddress)}
                                />
                            }

                            { customerSubscription &&
                                <div className="card-section section">
                                    <div className="card-title title">{strings["USER_PROFILE_VIEW_HEADLINE_PAYMENT_DATA"]}</div>

                                    {!paymentInput.open
                                        ? <div>
                                            <div className="grey-section">
                                                <div className="payment-logo">
                                                    <img src={paymentMethod?.iconUrl} alt="" />
                                                    <p>{paymentMethod?.label}</p>
                                                </div>
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                <a className="card-link" href="#" onClick={handlePaymentClick}>{strings["GENERAL_CHANGE"]}</a>
                                            </div>

                                            {hasPaymentIssue
                                                ? <StaticNotification text={strings["PAYMENT_ISSUE_NOTIFICATION"]} isWarning={true} />
                                                : null
                                            }

                                            {paymentInput.errorMessage
                                                ? <div className="error-message">
                                                    {paymentInput.errorMessage}
                                                </div>
                                                : null
                                            }

                                            <InfoBox message={strings["USER_PROFILE_VIEW_HINT_PAYMENT_DATA"]} />
                                        </div>
                                        : null
                                    }

                                    {paymentInput.open
                                        ? <div className="payment-input">
                                            <div id="braintree-container" className="braintree-container" />

                                            <div className="button-container">
                                                <Button onClick={handleDialogClose} className="default-button">
                                                    {strings["GENERAL_CANCEL"]}
                                                </Button>
                                                <Button onClick={handleDialogConfirm} className="green-button"
                                                    disabled={!paymentInput.braintreeRequestable}>
                                                    {strings["GENERAL_CONFIRM"]}
                                                </Button>
                                            </div>
                                        </div>
                                        : null
                                    }

                                </div>
                            }

                            {customerPostIdent?.status === PostIdentStatus.SUCCESS
                                ? <div className="kundenkarte-section section">
                                    <div className="title">{strings["BOX_DETAIL_TITLE_CHIPCARD"]}</div>
                                    <Button variant='outlined' className="outline-button"
                                        onClick={() => history.push(dashboardPaths.userDataCard)}>
                                        {(!customerGunnebo.hasActiveCard || customerGunnebo.hasBlockedCard) ? strings["USER_PROFILE_VIEW_BTN_REGISTER_KEY_CARD"] : strings["USER_PROFILE_VIEW_BTN_KEY_CARD_DETAILS"]}
                                    </Button>
                                </div>
                                : null
                            }
                        </div>
                        : <InfoBox message={strings["USER_PROFILE_VIEW_HINT_ADDRESS_DATA"]} />
                }

                <div className="password-section section">
                    <div className="title">{strings["USER_PROFILE_VIEW_HEADLINE_PASSWORD"]}</div>
                    <Button variant='outlined' className="outline-button"
                        onClick={() => history.push(dashboardPaths.userDataPassword)}>{strings["USER_PROFILE_VIEW_BTN_CHANGE_PASSWORD"]}</Button>
                </div>

                <div className="einstellunen-section section">
                    <div className="title">{strings["USER_PROFILE_VIEW_HEADLINE_PRIVACY_SETTINGS"]}</div>
                    <Button variant='outlined' className="outline-button"
                        onClick={() => window.UC_UI.showSecondLayer()}>{strings["USER_PROFILE_VIEW_BTN_CHANGE_PRIVACY_SETTINGS"]}</Button>
                </div>

                <div className="privacy-policy">
                    <a href="https://legal.asservato.de/datenschutz" target="_blank" rel="noopener noreferrer" className={'text-button'}>{strings["REGISTER_VIEW_TERMS"]}</a>
                    <a href="https://legal.asservato.de/agb" target="_blank" rel="noopener noreferrer" className={'text-button'}>{strings["USER_PROFILE_VIEW_BTN_TERMS"]}</a>
                    <a href="https://www.asservato.de/impressum" target="_blank" rel="noopener noreferrer" className={'text-button'}>{strings["USER_PROFILE_VIEW_BTN_IMPRINT"]}</a>
                </div>
            </div>
        </div>
    )
}
